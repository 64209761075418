import store from '@/store';
import { IsPC , encrypt} from '@/utils'
import storageUtils from '@/utils/storageUtils'
import iframeConfig from '@/custom/iframeConfig'
import { groupIdKey } from '@/utils/storageUtils';
import constant from '@/constant/constant'
import { Message , MessageBox } from 'element-ui';
const socketConfig = {
	
	//webSocket连接地址
	webSocketUrl: '',
	lockReconnect:false,//避免重复连接
	lockReconnectCount:0,
	//socket实例
	socket: null,
	hanlderInset:null,
	//初始化长连接
	initSocket: function() {
	
			let url='identify=guest&mch_token='+store.state.socket.socketParams.mch_token+'&uuid='+store.state.socket.socketParams.uuid+
			'&view_id='+store.state.socket.socketParams.view_id+'&otherkey='+store.state.socket.socketParams.otherkey+
			'&guest_mch_id='+store.state.socket.socketParams.guest_mch_id;
			
			let aes=store.state.socket.socketParams.mch_token+store.state.socket.socketParams.uuid+store.state.socket.urlParams.timestamp
			this.webSocketUrl= process.env.VUE_APP_SOCKET_URL+'?'+url+'&sign='+encrypt(aes);

			/* 初始化socket实例 */
			this.socket = new WebSocket(this.webSocketUrl);
			/* 连接打开 */
			this.socket.onopen = this.socketOpen;
			/* 连接监听 */
			this.socket.onmessage = this.socketMessage;
			/* 连接关闭 */
			this.socket.onclose = this.socketClose;
			this.socket.onerror= this.socketError;


	},
	socketOpen: function(){
	},
	reconnect: function() {
	  var tt;
	   if(socketConfig.lockReconnect) {
			return;
	   };
	   if(socketConfig.lockReconnectCount>5){
		   return MessageBox('您的网络出了点问题,请刷新重试!', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
         }).then(() => {
				iframeConfig.postMessage('reload','');
         })
	   }
	   socketConfig.lockReconnect = true;
	   //没连接上会一直重连，设置延迟避免请求过多
	   tt && clearTimeout(tt);
	   tt = setTimeout(()=>{
		 socketConfig.lockReconnectCount++
	     socketConfig.initSocket()
	     socketConfig.lockReconnect = false;
	   }, parseInt(socketConfig.lockReconnectCount*2000 + 2000));
	
	 },
	hanlderStart:function(){
		if(socketConfig.lockReconnectCount>0){
			 Message({message:'连接已恢复!',type:'success'})
		}
		let params={type:'ping'}
		 socketConfig.lockReconnectCount=0
		 socketConfig.hanlderInset&&clearInterval(socketConfig.hanlderInset)
	     socketConfig.hanlderInset=setInterval(()=>{
		 socketConfig.sendMessge(params)
	   },14888); 
	},
	socketMessage: function(e){
		let message = JSON.parse(e.data);
		store.commit('SET_SOCKETFLAG', true);
		if(message.type == 'ping'){
		  //socket心跳响应
		}else if(message.type == constant.messageType.access){
			socketConfig.hanlderStart()
			let backdata={
				base_cfg:message.site.base_cfg,
				kefu:message.data
			}
		   store.commit('SET_BASECONFIG', message.site);
		   let urlParams=store.state.socket.urlParams;
		    urlParams.timestamp= message.time;
			store.commit('SET_URLPARAMS', urlParams);
			iframeConfig.postMessage('socketSucceed', backdata);
			store.commit('SET_STYLECONFIGOBJ', message.site.style.style);
			store.commit('SET_BASECONFIG', message.site);
			iframeConfig.fenpei(message)
			
		}else if(message.type == constant.messageType.chatLeave){
			//无客服显示留言界面
			socketConfig.hanlderStart()
			iframeConfig.postMessage('setLeav', '留言表单');
			store.commit('SET_FORMFIELD', message.data);
		    store.commit('SET_FENPEIFLAG', false);
			
		}else if(message.type == constant.messageType.textType || message.type == constant.messageType.imageType
			 || message.type == constant.messageType.welcomeType || message.type == constant.messageType.robotType 
			 || message.type == constant.messageType.systemType || message.type == constant.messageType.notAnswerType){
			//将消息插入当前会话列表
			store.commit('SET_Close', false);
			store.commit('pushCurrentMessageList', {'info': message, 'type': 'one'});
		}else if(message.type == 'message'){
			//将消息插入当前会话列表
			store.commit('SET_Close', false);
			store.commit('pushCurrentMessageList', {'info': message.data, 'type': 'one'});
		}else if(message.type == constant.messageType.invite){
			iframeConfig.postMessage('inviteWindow', message);
		}
		else if(message.type == constant.messageType.guideType){
			//发送到父级窗口
			if(!store.state.socket.fenPeiFlag){
				iframeConfig.postMessage('bubbleInfo', message);
			}
		}else if(message.type == constant.messageType.inviteType){
			//唤起邀请弹窗
			iframeConfig.postMessage('inviteWindow');
		}
		// else if(message.type == constant.messageType.newConversation){
		// 	//唤起聊天弹窗
		// 	iframeConfig.postMessage('chatWindow', message);
			
		// 	storageUtils.setVisitorInfo(store.state.socket.mch_id, groupIdKey, message.content.guest.chat_group_id);
		//     store.commit('SET_CUSTOMERINFO', JSON.parse(JSON.stringify(message.content)));
		// }
		else if(message.type == constant.messageType.closeConversation){
			//将消息插入当前会话列表
			store.commit('pushCurrentMessageList', {'info': message, 'type': 'one'});
		}
	},
	socketError:function(e){
		  clearInterval(socketConfig.hanlderInset)

		  socketConfig.reconnect()
	},
	/* 连接关闭 */
	socketClose: function(e){
		 console.log('连接断开',888)
		if(socketConfig.lockReconnectCount==0){
			 Message({message:'网络已断开!',type:'error'})
		}
		store.commit('SET_SOCKETFLAG', false);
		 //清除心跳
		 clearInterval(socketConfig.hanlderInset)
		 // //开始重连
		socketConfig.reconnect()
	},
	sendMessge: function(param){
		if(socketConfig.socket.readyState===1){
			 socketConfig.socket.send(JSON.stringify(param));
		}else{
			 Message({message:'请检查您的网络!',type:'error'})
		}
		
	},
	/*上传访客轨迹 并 绑定访客 */
	trackBind(uuid, page_title, page_url, client_id){
		//来源参数追加
		store.state.socket.urlParams['uuid'] = uuid || store.state.socket.urlParams['uuid'];
		store.state.socket.urlParams['client_id'] = client_id;
		store.state.socket.urlParams['device'] = IsPC ? 'pc' : 'mobile';
		
		//上传访客轨迹
			store.dispatch("visitTrack", store.state.socket.urlParams).then((res) => {
				//保存view_id    
				storageUtils.setVisitorInfo(store.state.socket.mch_id, 'view_id', res.id);
				//访客绑定
				store.dispatch('socketBind', {
					uuid: uuid,   
					client_id: client_id,
					view_id: res.id,
					site_id: store.state.socket.site_id || 1,
					guest_mch_id: store.state.socket.customer_mch_id
				}).then((res) => {
					//保存绑定成功标识
					store.commit('SET_BINDFLAG', true);
					//保存访客对话数量
					store.commit('SET_CHATCOUNT', res.chat_count);
					socketConfig.hanlderStart()
				});
			});
	}
	
}

export default socketConfig
