import axios from 'axios'
import store from '@/store';
import { Base64 } from 'js-base64'
import socketConfig from '@/custom/socketConfig'
import { Message } from 'element-ui'
import storageUtils from '@/utils/storageUtils'
import { visitorKey, authorizationKey, mchIdKey } from '@/utils/storageUtils'
import constant from '@/constant/constant'

// create an axios instance
const service = axios.create({
  //baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let enterprise = `${store.state.socket.mch}:${Math.round(new Date().getTime()/1000)}`;
	//let enterprise = `1:${Math.round(new Date().getTime()/1000)}`;
    config.headers[authorizationKey] = Base64.encode(enterprise);
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
		Message({
		  message: res.msg || '网络错误',
		  type: 'error',
		  duration: 5 * 1000
		})
	  return res;
    } else {
      return res;
    }
  },
  error => {
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
