import store from '@/store';
export const messageListKey = 'xiaoDaoMessageList';
export const groupIdKey = 'xiaoDaoGroupId';
export const visitorKey = 'xiaoDaoVisitor';
export const authorizationKey = 'Authorization';
const storageUtils = {
	
	setSessionStorage(key, value) {
	  sessionStorage.setItem(key, value);
	},
	
	getSessionStorage(key) {
	  sessionStorage.getItem(key);
	},
	
	//保存访客字段信息
	setVisitorInfo(mchIdKey, fieldKey, fieldValue) {
	  let VisitorInfo = this.getVisitorInfo();
	  VisitorInfo[fieldKey] = fieldValue;
	  localStorage.setItem(mchIdKey, JSON.stringify(VisitorInfo));
	},

	//获取访客信息
    getVisitorInfo() {
      return localStorage.getItem(store.state.socket.mch_token)?JSON.parse(localStorage.getItem(store.state.socket.mch_token)):{}
    }

}

export default storageUtils
