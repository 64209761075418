import { create, visitTrack, fenpei ,init } from '@/api/user';
import { groupIdKey, visitorKey } from '@/utils/storageUtils';
import storageUtils from '@/utils/storageUtils';
import store from '@/store';

const user = {

	state: {
		
		currentUserProfile: {},
		visitorInfo: {},
		group_id: ''
		
	},
	mutations: {

		SET_VISITORINFO: (state, visitorInfo) => {
			state.visitorInfo = visitorInfo;
		},
		
		SET_GROUPID: (state, group_id) => {
			state.group_id = group_id;
		}

	},
	actions: {

	
		//访客登录
		create({commit}) {
			return new Promise((resolve, reject) => {
				create().then(response => {
					const {data} = response;
					//保存访客信息
					commit('SET_VISITORINFO', data);
					storageUtils.setVisitorInfo(store.state.socket.mch_id, visitorKey, data);
					resolve(data);
				}).catch(error => {
					reject(error);
				})
			})
		},
		
		//上传访客轨迹
		visitTrack({commit}, params) {
			return new Promise((resolve, reject) => {
				visitTrack({
					uuid: params.uuid,
					device: params.device,
					page_title: params.page_title,
					page_url: params.page_url,
					referer_url: params.referer_url,
					client_width: params.client_width,
					client_height: params.client_height,
					os: params.os,
					browser: params.browser,
					lang: params.lang,
					visit_id: params.visit_id,
					site_id:params.site_id || 1,
					otherkey:params.otherkey
				}).then(response => {
					const { data } = response;
					resolve(data);
				}).catch(error => {
					reject(error);
				})
			})
		},
		
		//请求接入对话，分配客服
		fenpei({commit}, params) {
			return new Promise((resolve, reject) => {
				fenpei(params).then(response => {
					const { data } = response;
					//保存group_id
					commit('SET_GROUPID', data.id);
					storageUtils.setVisitorInfo(store.state.socket.mch_id, groupIdKey, data.id);
					resolve(response);
				}).catch(error => {
					reject(error);
				})
			})
		}

	}

}

export default user
