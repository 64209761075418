import Vue from 'vue'
import { socketBind, imSend, fieldsFrom, beforechat, guestbook,
 formSub} from '@/api/custom';
import storageUtils from '@/utils/storageUtils';
import iframeConfig from '@/custom/iframeConfig';
import constant from '@/constant/constant'


const socketModules = {
	state: {
		socketParams:{ mch_token:'' ,uuid:'' ,view_id:0 ,otherkey:'',guest_mch_id:0},
		manner: '' , //触发方式
		customer_mch_id: '' , //客服商家id
		urlParams: {} , //url来源参数
		current: Date.now(), // 当前时间
		message: undefined,
		socketFlag:false,//socket连接
		currentConversation: {group_id:0,}, //当前会话的对象
		currentMessageList: [], //当前的会话消息列表nai
		nextReqMessageID: '',
		isCompleted: false, //当前会话消息列表是否已经拉完了所有消息
		conversationList: [] ,//会话用户列表
		newMessageFlag: false ,//新消息接受标志
		chat_count: 0 ,//访客对话数量
		fenPeiFlag: false,//是否已分配客服标识
		isClose:false,//是否关闭
		chatFromFlag:false,
		customerInfo: {} ,//客服信息,
		form_field:{},
		activeName: '1' ,//自助问答激活名称
		selfHelpGroups: [] ,//自助问答类别信息
		selfhelpfqas: [] ,//自助问答问题信息
		off_sound: true,
		leaveSubmitFlag:false
	},
	getters: {
		
		// 用于当前会话的图片预览
		imgUrlList: state => {
		  return state.currentMessageList.filter(message => message.type === constant.messageType.imageType).map(message => message.content) // 筛选出没有撤回并且类型是图片类型的消息
		}

	},
	mutations: {
		
		SET_LEAVEFLAG:(state,res)=>{
			state.leaveSubmitFlag=res
		},
		SET_currentConversation:(state,res)=>{
			state.currentConversation=res
		},
		//表单字段列表
		SET_FORMFIELD:(state,res)=>{
			state.form_field=res.form_field;
		},
		SET_Close:(s,m)=>{
			s.isClose=m
		},
		SET_MSG:(s,m)=>{
			s.currentMessageList=m
		},
		//保存触发方式
		SET_MANNER: (state, manner) => {
			state.manner = manner;
		},

		//保存商家token
		SET_MCH_TOKEN: (state, socketParams) => {
			state.socketParams ={...state.socketParams,...socketParams};
		},
		//保存url来源参数
		SET_URLPARAMS: (state, urlParams) => {
			state.urlParams = urlParams;
		},
		//保存自助问答激活名称
		SET_ACTIVENAME: (state, activeName) => {
			state.activeName = activeName;
		},
		//保存是否开启了询前表单标识
		SET_BEFORECHATFROMFLAG: (state, beforeChatFromFlag) => {
			state.beforeChatFromFlag = beforeChatFromFlag;
		},
		//保存绑定是否成功标识
		SET_SOCKETFLAG: (state, socketFlag) => {
			state.socketFlag = socketFlag;
		},
		//保存访客对话数量
		SET_CHATCOUNT: (state, chat_count) => {
			state.chat_count = chat_count;
		},
		//保存是否已分配客服标识
		SET_FENPEIFLAG: (state, fenPeiFlag) => {
			state.fenPeiFlag = fenPeiFlag;
		},
		//保存客服信息
		SET_CUSTOMERINFO: (state, customerInfo) => {
			state.customerInfo = customerInfo;
		},
		//保存自助问答类别信息
		SET_SELFHELPGROUPS: (state, selfHelpGroups) => {
			state.selfHelpGroups = selfHelpGroups;
		},
		
		//保存自助问答问题信息
		SET_SELFHELPFQAS: (state, selfhelpfqas) => {
			state.selfhelpfqas = selfhelpfqas;
		},
		SET_SETCANCELLOADING:(state) => {
			state.currentMessageList=state.currentMessageList.filter(r=>r.type!='imgloading')
		},
		/**
		* 保存声音开关标识
		*/
		SET_OFFSOUND: (state, off_sound) => {
			state.off_sound = off_sound;
		},
		
		/**
		 * 将消息插入当前会话列表
		 * 调用时机：收/发消息事件触发时
		 * @param {Object} state
		 * @param {Message[]|Message} data
		 * @returns
		 */
		pushCurrentMessageList(state, data) {
			var tt=null;
			//初始加载
			if(data.type == 'init'){
			  state.currentMessageList = data.info;
			}
			
			//查询更多
			if(data.type == 'more'){
			  if(data.length > 0){
			    state.currentMessageList = [...data.info, ...state.currentMessageList];
			  }else{
			    state.isCompleted = true;
			  }
			}
			
			//单条信息
			if(data.type == 'one'){
				
	
				state.currentMessageList=state.currentMessageList.filter(r=>r.type!='loading')
				if(data.info.type===constant.messageType.closeConversation){
					state.isClose=true
				}
			  //发送到父级窗口
			  iframeConfig.postMessage('bubbleInfo', data.info);
			  state.currentMessageList = [...state.currentMessageList, data.info];
			  localStorage.setItem('msg_'+state.socketParams.mch_token, JSON.stringify(state.currentMessageList));
			  if(data.info.sender_type=='guest'){
				  if(data.info.type==constant.messageType.imageType){
				    state.currentMessageList=state.currentMessageList.filter(r=>r.type!='imgloading')
				  }else{
					  let userList=state.currentMessageList.filter(r=>r.sender_type=='user').reverse();
					    if(userList.length==0){
					  						 userList=[{sender_name:state.customerInfo.user_nickname}]
					  					 }
					   let date=Date.parse(new Date())/1000
					  					if (tt) {
					  					    clearTimeout(tt)
					  					}
					   tt=setTimeout(()=>{
					  					    state.currentMessageList.push({
					  					   	chatid:1,
					  					   	sender_name:userList[0].sender_name,
					  					   	send_time:date,
					  					   	type:'loading'
					  					      })
					  					   state.newMessageFlag = !state.newMessageFlag;
					  }, 500);
				  }
				
				   
			
			}else{
					state.currentMessageList=state.currentMessageList.filter(r=>r.type!='loading')
			}
			  // if(data.info.chatid != storageUtils.getVisitorInfo().xiaoDaoVisitor.chatid 
			  // && data.info.type != constant.messageType.closeConversation){
			  // 	//发送到父级窗口
			  // 	iframeConfig.postMessage('bubbleInfo', data.info);
			  // }
			  state.newMessageFlag = !state.newMessageFlag;
			  if(state.off_sound && data.info.sender_uid != state.customerInfo.user_id){
			    Vue.prototype.$audioXxx.play(); //如果开启了消息提醒，那么播放声音
			  }
			}
			//自助问答信息
			if(data.type == 'SELF_HELP' || data.type == 'TURN_MSG'){
			  state.currentMessageList = [...state.currentMessageList, data];
			  state.newMessageFlag = !state.newMessageFlag;
			}
			
		}

	},
	actions: {
		
		
		//WebSocket发送消息
		sendMessage({commit}, params) {
			return new Promise((resolve, reject) => {
				imSend(params).then(response => {
					const { data } = response;
					resolve(data);
				}).catch(error => {
					reject(error);
				})
			})
		},
		
		//表单字段查询
		fieldsFrom({commit}, params) {
			return new Promise((resolve, reject) => {
				fieldsFrom({
					form_name: params.form_name
				}).then(response => {
					const { data } = response;
					resolve(data);
				}).catch(error => {
					reject(error);
				})
			})
		},
		//表单提交
		formSub({commit}, params){
			return new Promise((resolve, reject) => {
				formSub(params).then(response => {
					const { data } = response;
					resolve(data);
				}).catch(error => {
					reject(error);
				})
			})
		},
		//询前表单提交接口
		beforechat({commit}, params) {
			return new Promise((resolve, reject) => {
				beforechat(params).then(response => {
					const { data } = response;
					resolve(data);
				}).catch(error => {
					reject(error);
				})
			})
		},
		
		//留言表单提交接口
		guestbook({commit}, params) {
			return new Promise((resolve, reject) => {
				guestbook(params).then(response => {
					const { data } = response;
					resolve(data);
				}).catch(error => {
					reject(error);
				})
			})
		}

	}
}

export default socketModules
