export default {

  //http根路径
  //baseURL: 'https://api.51xd.cn',

  //动态表单组件映射
  fieldMap: {
	'input': 'el-input',
	'textarea': 'el-input'
  },

  //消息类型
  messageType: {
    'textType': 'GROUP_MSG_TEXT',
    'imageType': 'GROUP_MSG_IMAGE',
	'inviteType': 'NOTICE_INVITE',
	'newConversation': 'NOTICE_NEW_CONVERSATION',
	'welcomeType': 'WELCOME_AUTO_MSG',
	'guideType': 'GUIDE_AUTO_MSG',
	'systemType': 'SYSTEM_REPLY_MSG',
	'robotType': 'ROBOT_REPLY_MSG',
	'notAnswerType': 'NOT_ANSWER_REPLY_MSG',
	'closeConversation': 'NOTICE_CLOSE_CONVERSATION',
	'selfHelp': 'SELF_HELP',
	'turnType': 'TURN_MSG',
	'chatLeave':'CHAT_LEAVE',
	'access':'ACCESS',
	'invite':'INVITED_OPEN_TALK',
	'loginout':'SSO_SIGN_OUT'
  }

}
