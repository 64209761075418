<template>
  <div id="app">
    <router-view />
	<!-- 新消息提示音 -->
	<audio controls="controls" src="./assets/music/xxx.mp3" ref="audioXxx" hidden="true" ></audio>
  </div>
</template>

<script>
	
import Vue from 'vue';

export default {
  name: 'App',
  mounted() {
      //保存语音组件的引用
      Vue.prototype.$audioXxx = this.$refs.audioXxx;
	
  }
}
</script>

<style type="text/css">

  :focus{
    outline:0;
  }
  
  body{
	margin: 0;
  }

</style>
