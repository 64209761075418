import store from '@/store';
import socketConfig from '@/custom/socketConfig'
import storageUtils from '@/utils/storageUtils'
import iframeConfig from '@/custom/iframeConfig'
import {
	MessageBox,
	Message
} from 'element-ui'
import {
	getBaseConfig,
	selfhelpgroups,
	selfhelpfqa,
	mchGetOne
} from '@/api/custom'
import {
	guestTransfer,
	hasKefu
} from '@/api/user'
import {
	throttle
} from "@/utils/index.js"

const iframe = {

	eventSucceedInterval: null,

	//iframe初始化
	init: function() {
		//侦听父窗口传递过来的参数
		this.windowEventListener();
	},

	//侦听父窗口传递过来的参数
	windowEventListener: function() {
		let _this = this;
		window.addEventListener('message', function(e) {
			let message = e.data;
			switch (message.type) {
				case 'advisory':
					 socketConfig.socket.send(JSON.stringify(message.data));
					break;
					//清除定时任务
				case 'eventListenerSucceed':
					clearInterval(iframe.eventSucceedInterval);
					let {
						mch_token, uuid, view_id, otherkey, guest_mch_id
					} = message.data;
					store.commit('SET_MCH_TOKEN', {
						mch_token,
						uuid,
						view_id,
						otherkey,
						guest_mch_id
					});
					//初始化长连接
					socketConfig.initSocket();
					break;
					//是否开启声音提醒
				case 'off_sound':
					//保存是否开启声音提醒
					store.commit('SET_OFFSOUND', message.content);
					break;
				default:
					break;
			}
		}, false);

		//启动定时任务
		iframe.eventSucceedInterval = setInterval(function() {
			//通知父级窗口 iframe内部的EventListener创建成功
			iframe.postMessage('eventListenerSucceed', 'ok');
		}, 333);
	},

	//返回响应对象
	postMessage: function(operation, data) {
		window.parent.postMessage({
			'operation': operation,
			'data': data
		}, store.state.socket.urlParams.parentWindowUrl);
	},

	//处理分配客服信息
	fenpei: function(res) {
		let _this = this;

		store.commit('SET_Close', false);
		let msgkey = 'msg_' + store.state.socket.socketParams.mch_token
		if (localStorage.getItem(msgkey)) {
			let messge = JSON.parse(localStorage.getItem(msgkey))
			store.commit('SET_MSG', messge);
		}
		store.commit('SET_FENPEIFLAG', true);
		store.commit('SET_CUSTOMERINFO', JSON.parse(JSON.stringify(res.data)));
		
	},

	//单独传轨迹
	updataGj: function(keyValue) {
		let params = store.state.socket.urlParams;
		params.otherkey = keyValue
		store.dispatch("visitTrack", params)
	}

}

export default iframe
