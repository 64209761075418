import request from '@/utils/request'

//访客登录
export function create(data) {
  return request({
    url: '/guest/create',
    method: 'post',
    data
  })
}

//访客登录
export function init(data) {
  return request({
    url: '/index/init',
    method: 'post',
    data
  })
}


//上传访客轨迹
export function visitTrack(data) {
  return request({
    url: '/guest_view/submit',
    method: 'post',
    data
  })
}

//分配客服
export function fenpei(data) {
  return request({
    url: '/guest/fenpei',
    method: 'post',
    data
  })
}

//是否有可分配客服
export function hasKefu(data) {
  return request({
    url: '/chat/hasKefu',
    method: 'post',
    data
  })
}

//访客转人工客服
export function guestTransfer(data) {
  return request({
    url: '/chatgroup/guestTransfer',
    method: 'post',
    data
  })
}
