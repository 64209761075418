import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import Avatar from './components/avatar.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import iframeConfig from '@/custom/iframeConfig'
import socketConfig from '@/custom/socketConfig'
import storageUtils from '@/utils/storageUtils'
import constant from '@/constant/constant'
import { IsPC } from '@/utils'

import './assets/icon/iconfont.css'
import './assets/icon/tim.css'

import '@/styles/index.scss' // global css
// set ElementUI lang to EN
Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)
Vue.prototype.baseImgUrl=process.env.VUE_APP_IMG_URL
Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.config.productionTip = false;
Vue.prototype.$iframeConfig = iframeConfig;
Vue.prototype.$socketConfig = socketConfig;
Vue.prototype.$storageUtils = storageUtils;
Vue.prototype.$constant = constant;
Vue.prototype.$IsPC = IsPC(); //来源是否为PC端

Vue.component('avatar', Avatar);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
