<template>
  <div class="avatar" :class="shape === 'circle' ? 'shape-circle' : ''">
    <img :src="avatarSrc">
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    type: {
      type: String,
      default: 'C2C'
    },
    shape: {
      type: String,
      default: 'circle'
    }
  },
  computed: {
    avatarSrc: function () {
      let src = this.src
      if (/^(https:|http:|\/\/)/.test(src)) {
        return src
      } else {
        return this.defaultSrc
      }
    },
    defaultSrc: function () {
      //个人头像
      return 'https://imgcache.qq.com/open/qcloud/video/act/webim-avatar/avatar-2.png'
    }
  }
}
</script>

<style lang="stylus" scoped>
.avatar
  background-color $first
  text-align center
  width 100%
  height 100%
  overflow hidden
  img
    width 100%
    height 100%
.shape-circle
  border-radius 50%
</style>
