import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import iframe from './modules/iframe'
import socket from './modules/socket'
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
	iframe,
	socket,
    user
  },
  getters
})

export default store
