import { getStyleConfig } from '@/api/custom';

const iframeModules = {
	state: {
		ad:{},
		
		pcWindowProblemList:[],
		
		baseConfig: null,
		
		pcWindowStyle: null,
		
		pcInviteStyle: null,
		
		pcButtonStyle: null,
		
		mvWindowStyle: null,
		
		mvInviteStyle: null,
		
		mvButtonStyle: null
		
	},
	getters: {

	},
	mutations: {
		
		SET_BASECONFIG: (state, baseConfig) => {
			state.baseConfig = baseConfig.base_cfg;
			state.pcWindowProblemList = baseConfig.style.faq;
			state.ad = baseConfig.ad;
		},
		
		SET_STYLECONFIGOBJ: (state, styleConfigObj) => {
			for (var i=0; i<styleConfigObj.length; i++){
				if(styleConfigObj[i].device == 1){
					if(styleConfigObj[i].element_id == 1){
						state.pcButtonStyle = JSON.parse(styleConfigObj[i].value_text);
					}
					if(styleConfigObj[i].element_id == 2){
						state.pcInviteStyle = JSON.parse(styleConfigObj[i].value_text);
					}
					if(styleConfigObj[i].element_id == 3){
						state.pcWindowStyle = JSON.parse(styleConfigObj[i].value_text);
						console.log(state.pcWindowStyle);
					}
				}
				if(styleConfigObj[i].device == 2){
					if(styleConfigObj[i].element_id == 1){
						state.mvButtonStyle = JSON.parse(styleConfigObj[i].value_text);
					}
					if(styleConfigObj[i].element_id == 2){
						state.mvInviteStyle = JSON.parse(styleConfigObj[i].value_text);
					}
					if(styleConfigObj[i].element_id == 3){
						state.mvWindowStyle = JSON.parse(styleConfigObj[i].value_text);
					}
				}
			}

		},

	}

}

export default iframeModules
