import request from '@/utils/request'


//聊天图片上传
export function updateImg(data) {
  return request({
    url: '/attach/uploadForChat',
    method: 'post',
    headers:{
      'Content-Type': 'application/json;charset=UTF-8;multipart/form-data;'
    },
    data
  })
}

//获取基础配置
export function getBaseConfig(data) {
  return request({
    url: '/index/getcfg',
    method: 'get',
    data
  })
}

//消息发送：访客
export function imSend(data) {
  return request({
    url: '/group_msg/sendByGuest',
    method: 'post',
    headers:{
      'Content-Type': 'application/json;charset=UTF-8;multipart/form-data;'
    },
    data
  })
}

//访客绑定
export function socketBind(data) {
  return request({
    url: '/chat/bindGuest',
    method: 'post',
    data
  })
}

//获取风格配置
export function getStyleConfig(data) {
  return request({
    url: '/style/get',
    method: 'post',
    data
  })
}

//表单字段查询
export function fieldsFrom(data) {
  return request({
    url: '/form/formFields',
    method: 'post',
    data
  })
}

//表单提交
export function formSub(data) {
  return request({
    url: '/form/submit',
    method: 'post',
    data
  })
}

//询前表单提交
export function beforechat(data) {
  return request({
    url: '/beforechat_data/submit',
    method: 'post',
    data
  })
}

//留言表单提交
export function guestbook(data) {
  return request({
    url: '/guestbook_data/submit',
    method: 'post',
    data
  })
}

//获取商家单个配置
export function mchGetOne(data) {
  return request({
    url: '/mch_config/getOne',
    method: 'post',
    data
  })
}

//获取对话广告
export function getad(params) {
  return request({
    url: '/automsg/getad',
    method: 'get',
    params
  })
}

//获取常见问题
export function problemPlist(data) {
  return request({
    url: '/problem/plist',
    method: 'get',
    data
  })
}

//自助问答类别获取
export function selfhelpgroups(data) {
  return request({
    url: '/robot/selfhelpgroups',
    method: 'get',
    data
  })
}

//自助问答FQA
export function selfhelpfqa(data) {
  return request({
    url: '/robot/selfhelpfqa',
    method: 'POST',
    data
  })
}

//消息发送：机器人自助
export function groupSendByRobot(data) {
  return request({
    url: '/group_msg/sendByRobot',
    method: 'POST',
    data
  })
}
